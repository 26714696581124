<template>
  <!-- 新 - 首页 - 整合营销 -->
  <div class="courseCenter">
    <headPage />
    <!-- <carousel /> -->
    
    <div style="width:100%">
      <!-- <img src="../../assets/mukeCooperation/mukeBanner.png" alt="" width="100%"></img> -->
       <el-image
          style="width: 100%; height: 395px"
          :src="require('@/assets/mukeCooperation/mukeBanner.png')"
          fit="cover"
        ></el-image>
    </div>
    <div class="muKeCooperationBgBox">
      <div class="muKeCooperation">
        <!-- <p class="p_1">全景多维运营体系 高效支撑赋能服务</p> -->
        <h1 style="padding: 3rem 0; text-align: center; font-size: 34px">
          入驻福利
        </h1>
        <div class="muKeSettlementBenefits">
          <div
            v-for="(item, index) in SettlementBenefits"
            :key="index"
            class="SettlementBenefitsBox"
          >
            <div
              :style="{ 'background-image': 'url(' + item.bg + ')' }"
              class="SettlementBenefitsBg"
            >
              <h2 v-html="item.title">
                {{ item.title }}
              </h2>
            </div>
            <div class="SettlementBenefits_bottom">
              <p v-html="item.subTitle" class="SettlementBenefits_subTitle">
                {{ item.subTitle }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="muKeCooperationEntryQualificationBox">
      <div class="EntryQualification">
        <h1 style="padding: 3rem 0; text-align: center; font-size: 34px">
          入驻资格
        </h1>
        <div class="muKeEntryQualification">
          <div
            v-for="(item, index) in EntryQualification"
            :key="index"
            class="EntryQualificationBox"
          >
            <div class="EntryQualificationContent">
              <div>
                <img :src="item.icon" alt="" />
              </div>
              <h2>{{ item.title }}</h2>
              <p v-html="item.subTitle">{{ item.subTitle }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="muKeCooperationSettlementProcessBox">
      <div class="SettlementProcess">
        <h1 style="padding: 3rem 0; text-align: center; font-size: 34px">
          入驻流程
        </h1>
        <div class="muKeSettlementProcess">
          <div
            v-for="(item, index) in SettlementProcess"
            :key="index"
            class="SettlementProcessBox"
          >
            <div class="">
              <div class="SettlementProcessBoxImage">
                <img :src="item.icon" alt="" />
              </div>
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
        <div class="SettlementProcessLine"></div>
      </div>
    </div>
    <div class="muKeBgJoin">
      <h2>聚焦职业赋能，实现价值共赢</h2>
      <p>商务咨询:13333356080</p>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 94px;
        "
      >
        <el-button
          class="bgc-ms"
          style="background: #37c094; width: 150px;font-size:16px"
          @click="joinUsClick('1')"
          >名师入驻</el-button
        >
        <el-button
        class="bgc-jg"
          style="background: #2878FF; width: 150px;font-size:16px"
          @click="joinUsClick('2')"
          >机构入驻</el-button
        >
      </div>
    </div>
    <bottomPage />
    <el-dialog
      title=""
      class="popUp"
      width="600px"
      top="10%"
      :modal="false"
      :visible.sync="contactTeacherDialog"
      :before-close="editPopup"
    >
      <div class="contactTeacherPopup">
        <div class="dialogTop">
          <img :src="icon" alt="" />
          <span>{{ dialogTitle }}</span>
        </div>
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <el-form-item label="公司全称" v-if="type == '2'">
            <el-input v-model="form.companyName" placeholder="以营业执照为准" maxlength="50"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
          </el-form-item>

          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <div class="flexcc" style="margin-top:40px;">
            <el-button class="bgc-jg" @click="doSave('form')" style="width:180px;">提 交</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import headPage from "../wrongAuthenticatePage/headPage.vue";
import bottomPage from "./bottomPage.vue";
export default {
  name: "wrongAuthenticatePage_integratedMarketing",
  components: {
    headPage,
    bottomPage,
  },
  data() {
    return {
      //入驻福利
      SettlementBenefits: [
        {
          title: "平台支持",
          subTitle: "百万在线学习者<br/>开课即能触达精准用户",
          bg: require("@/assets/mukeCooperation/icon1.png"),
        },
        {
          title: "教研支持",
          subTitle: "专业课程教研制作团队<br/>帮助打磨精品课程内容",
          bg: require("@/assets/mukeCooperation/icon2.png"),
        },
        {
          title: "技术支持",
          subTitle: "成熟的后台管理系统<br/>及数据服务，实时查看",
          bg: require("@/assets/mukeCooperation/icon3.png"),
        },
        {
          title: "运营管理",
          subTitle: "专业营销矩阵与销售客服团队<br/>提升流量变现转化效率",
          bg: require("@/assets/mukeCooperation/icon4.png"),
        },
      ],
      //入驻资格
      EntryQualification: [
        {
          title: "行业名师",
          subTitle:
            "行业内有丰富的实战经验，具有一定知名度<br/>培训授课经验丰富",
          icon: require("@/assets/mukeCooperation/icon5.png"),
        },
        {
          title: "知名学者",
          subTitle: "权威教育背景<br/>行业内具有影响力的专家学者",
          icon: require("@/assets/mukeCooperation/icon6.png"),
        },
        {
          title: "知识网红",
          subTitle: "自媒体知识达人<br/>有一定数量粉丝群",
          icon: require("@/assets/mukeCooperation/icon7.png"),
        },
        {
          title: "实力机构",
          subTitle: "具有持续更新的优质课程资源<br/>或优质讲师资源",
          icon: require("@/assets/mukeCooperation/icon8.png"),
        },
      ],
      //入驻流程
      SettlementProcess: [
        {
          title: "提交申请",
          icon: require("@/assets/mukeCooperation/icon9.png"),
        },
        {
          title: "平台审核",
          icon: require("@/assets/mukeCooperation/icon10.png"),
        },
        {
          title: "洽谈合作",
          icon: require("@/assets/mukeCooperation/icon11.png"),
        },
        {
          title: "开展合作",
          icon: require("@/assets/mukeCooperation/icon12.png"),
        },
      ],
      contactTeacherDialog: false,
      form: {
        companyName: "",
        name: "",
        mobile: "",
      },
      rules: {
        mobile: [
          { required: false,  validator: this.$validatePhone, trigger: "blur" },
        ],
      },
      dialogTitle: "", //弹窗标题
      icon: "", //图标弹窗
      type: "", //类型
    };
  },
  created() {},
  computed: {},
  methods: {
    joinUsClick(type) {
      this.type = type;
      if (type == "1") {
        this.dialogTitle = "名师入驻";
        this.icon = require("@/assets/mukeCooperation/icon13.png");
      } else {
        this.dialogTitle = "机构入驻";
        this.icon = require("@/assets/mukeCooperation/icon14.png");
      }
      this.contactTeacherDialog = true;
    },
    doSave(form) {
      if (this.type == "1") {
        if (!this.form.name || !this.form.mobile) {
          this.$message("请先完善信息再提交!");
        } else {
          this.$refs[form].validate((valid) => {
            if (valid) {
              let parameter = {
                teacherName: this.form.name,
                teacherMobile: this.form.mobile,
              };
              this.$post("/biz/join/teacher/insert", parameter).then((res) => {
                if (res.status == 0) {
                  this.$message.success("提交成功，请等待平台审核");
                  // location.reload()
                  this.form = {};
                  this.contactTeacherDialog = false;
                }
              });
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        }
      } else {
        if (!this.form.name || !this.form.mobile || !this.form.companyName) {
          this.$message("请先完善信息再提交!");
        } else {
          this.$refs[form].validate((valid) => {
            if (valid) {
              let parameter = {
                contactsName: this.form.name,
                contactsMobile: this.form.mobile,
                companyName: this.form.companyName,
              };
              this.$post("/biz/join/company/insert", parameter).then((res) => {
                if (res.status == 0) {
                  this.$message.success("提交成功，请等待平台审核");
                  // location.reload()
                  this.form = {};
                  this.contactTeacherDialog = false;
                }
              });
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.courseCenter {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .muKeCooperationBgBox {
    background: #f9f9f9;
    padding-bottom: 60px;

    .muKeCooperation {
      width: 1168px;
      margin: auto;
      .p_1 {
        text-align: center;
        color: #3f4652;
        font-size: 26px;
        margin-bottom: 40px;
        margin-top: 60px;
      }
      .muKeSettlementBenefits {
        display: flex;
        justify-content: space-between;
        .SettlementBenefitsBox {
          width: 277px;
          border-radius: 8px;
          &:hover {
            box-shadow: 0px 0px 38px 0px rgba(87,174,244,0.1);
          }
          .SettlementBenefitsBg {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            h2 {
              color: #fff;
              text-align: center;
            }
          }
          .SettlementBenefits_bottom {
            background: #fff;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            .SettlementBenefits_subTitle {
              padding: 40px 28px 48px 28px;
              text-align: center;
              color: #666;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  //入驻资格
  .muKeCooperationEntryQualificationBox {
    background: #fff;
    padding-bottom: 60px;
    .EntryQualification {
      width: 1168px;
      margin: 0 auto;
      .muKeEntryQualification {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .EntryQualificationBox {
          width: 569px;
          height: 279px;
          box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.1);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:nth-child(3) {
            margin-top: 30px;
          }
          &:nth-child(4) {
            margin-top: 30px;
          }
          .EntryQualificationContent {
            height: 279px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            div {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            h2 {
              text-align: center;
              padding: 10px 0;
            }
            p {
              text-align: center;
              color: #666;
              font-size:16px;
            }
          }
        }
      }
    }
  }
  //入驻流程
  .muKeCooperationSettlementProcessBox {
    background: #f9f9f9;
    padding-bottom: 60px;
    .SettlementProcess {
      width: 1168px;
      margin: auto;
      .muKeSettlementProcess {
        display: flex;
        justify-content: space-between;
        .SettlementProcessBox {
          display: flex;
          .SettlementProcessBoxImage {
            width: 122px;
            height: 122px;
            border-radius: 50%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          p {
            text-align: center;
            font-weight: 500;
            color: #333;
            font-size: 17px;
            margin: 20px 0 47px;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background: #2878ff;
              bottom: -54px;
              left: 50%;
              transition: (-50%);
              transform: translate(-50%);
            }
          }
        }
      }
    }
    .SettlementProcessLine {
      height: 1px;
      border-bottom: 2px dashed #d1d1d1;
    }
  }
  //入驻
  .muKeBgJoin {
    background-image: url("../../assets/mukeCooperation/bg.png");
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    h2 {
      padding-top: 94px;
      font-size: 45px;
    }
    p {
      padding: 30px 0 45px 0;
      font-size: 23px;
    }
  }
}
.courseCenter {
  /deep/.el-dialog__wrapper {
    z-index: 9999 !important;
  }
  /deep/.el-dialog {
    background: transparent;
    border-radius: 20px;
  }
  /deep/.el-dialog__header {
    background: #fff;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    padding: 20px 20px 10px;
    .el-dialog__title {
      color: #333;
      font-size: 20px;
    }
    .el-dialog__headerbtn {
      top: 38px;
      .el-dialog__close {
        color: #333;
      }
    }
    .el-dialog__headerbtn:focus .el-dialog__close,
    .el-dialog__headerbtn:hover .el-dialog__close {
      color: #333;
    }
  }
  /deep/.el-dialog__body {
    background: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 0 30px;
  }
  .contactTeacherPopup {
    padding-bottom: 57px;
    .dialogTop {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 45px;
      span {
        font-size: 23px;
        color: #333;
      }
    }
  }
}
</style>
